import { useRouter } from "next/router";
import { UserButton, SignedIn, SignedOut, SignInButton } from "@clerk/nextjs";
import { useUser } from "@clerk/nextjs";
import { BiUser } from "react-icons/bi";
import { site } from "config";

import styles from "./UserButton.module.scss";
import SignUpSignInModal from "@/components/auth/clerk/SignUpSignInModal";

export default function UserButtonComponent({ mode = "default" }) {
  const { user } = useUser();
  let username;

  const router = useRouter();

  if (user) {
    if (user?.username) {
      username = user.username;
    } else if (user?.primaryEmailAddress?.emailAddress) {
      username = user?.primaryEmailAddress?.emailAddress;
    } else {
      username = user.id;
    }
  }

  return (
    <div className={`${styles.userButton}`}>
      <SignedIn>
        <div className={`${styles.accountButton}`}>
          {username && (
            <UserButton
              // afterSignOutUrl={`${site}${router.asPath}`}
              userProfileMode="navigation"
              userProfileUrl={`/user/${username}`}
            />
          )}
          {/* {!firstName && !lastName && ( */}
          {/* <span className={`${styles.userIcon}`}>
            <BiUser size={25} color="white" />
          </span> */}
          {/* )} */}
        </div>
      </SignedIn>
      <SignedOut>
        {mode === "default" && (
          <SignInButton
            forceRedirectUrl={`${site}${router.asPath}`}
            className={`${styles.signInButton}`}
            mode="modal"
          >
            <span className={`${styles.userIcon}`}>
              <BiUser size={27} color="white" />
            </span>
          </SignInButton>
        )}
        {mode === "custom" && (
          <SignUpSignInModal redirectUrl={`${site}/${router.asPath}`} />
        )}
      </SignedOut>
    </div>
  );
}
